import { reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default function usePageAPI(element) {
  const toast = useToast()

  const page = reactive({
    read: (storyId, name) =>
      store.dispatch('storyStore/readByPageName', { storyId, name }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.toString(),
            text: `Error al cargar la página ${name}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }),
    publish: (storyId, page) =>
      store
        .dispatch(`storyStore/publishPage`, { storyId, page })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Página actualizada',
              text: `Página "${page.name}" actualizada!`,
              icon: 'SendIcon',
              variant: 'success'
            }
          })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.toString(),
              text: `Error al publicar la página ${page.name}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
  })

  return toRefs(page)
}
