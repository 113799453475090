<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-tabs pills>
            <b-tab v-for="(page, index) in story.page" :key="index" :title="page.name.toUpperCase()">
              <page :pageName="page.name"></page>
            </b-tab>

            <b-tab :disabled="$session.user.role === `EDITOR`" lazy title="STORY SETTINGS">
              <storySettings :hidden="$session.user.role === 'EDITOR'"></storySettings>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BTabs, BTab, BSpinner } from 'bootstrap-vue'
import usePageAPI from '@/views/components/pageAPI/page'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BSpinner,

    page: () => import('@/views/components/pageAPI/page.vue' /* webpackChunkName: "pageAPI" */),
    storySettings: () => import('@/views/components/pageAPI/storySettings.vue' /* webpackChunkName: "storySettings" */)
  },
  data: () => ({
    tab: {
      home: true
      // blog: false
    }
  }),
  computed: {
    story() {
      return this.$store.state.storyStore.story
    }
  },
  setup() {
    const { read } = usePageAPI()

    return { read }
  },

  // mounted() {
  //   return this.$store.dispatch('storyStore/readBlogById', { storyId: this.$session.story._id }).then(() => {})
  // },
  // methods: {
  //   showTab() {
  //     this.tab.blog = true
  //   }
  // }
}
</script>
